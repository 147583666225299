<template>
  <!-- 导航栏 -->
  <div class="x-wrap">
    <div class="x-page-title">
      <h3>账号管理</h3>
    </div>
    <div class="x-main">
      <!-- 筛选条件 -->
      <div class="x-q-panel">
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="x-con-item">
              <label>查询：</label>
              <el-input
                placeholder="请输入用户ID/手机号/区块链地址"
                v-model="query.keyword"
              ></el-input>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="x-con-item">
              <label>启用状态：</label>
              <el-select placeholder="请选择启用状态" v-model="query.status">
                <el-option label="启用" value="1"></el-option>
                <el-option label="禁用" value="2"></el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="3">
            <el-button
              type="primary"
              class="x-q-btn"
              @click="
                query.pageIndex = 1;
                doQuery();
              "
              >筛选</el-button
            >
            <el-button type="text" @click="doQueryReset">重置</el-button>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="x-con-item mt25">
              <label>实名认证：</label>
              <el-select
                placeholder="请选择实名认证状态"
                v-model="query.authStatus"
              >
                <el-option label="未认证" value="1"></el-option>
                <el-option label="已认证" value="2"></el-option>
                <el-option label="认证中" value="3"></el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="x-con-item mt25">
              <label>注册时间：</label>
              <el-date-picker
                v-model="query.timeArr"
                type="daterange"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- 筛选结果 -->
      <div class="x-table-container">
        <div class="x-table-caption">
          <el-button
            icon="el-icon-refresh"
            type="primary"
            plain
            size="small"
            @click="doRefresh"
            >刷新</el-button
          >
          <!-- <el-button type="primary" class="fr" size="small" @click="doAdd"
            >添加账号</el-button
          > -->
        </div>

        <div
          class="x-table"
          v-loading="showLoading"
          element-loading-text="数据刷新中..."
        >
          <el-table :data="tableData" stripe style="width: 100%">
            <el-table-column prop="id" label="用户ID" width="100">
            </el-table-column>
            <el-table-column
              prop="nickName"
              label="昵称"
              width="180"
            ></el-table-column>
            <el-table-column
              prop="phoneNumber"
              label="手机号"
              fixed="left"
              width="200"
            ></el-table-column>
            <el-table-column prop="createdAt" label="注册时间" width="190">
              <template slot-scope="scope">
                <span>{{ scope.row.createdAt || "-" }}</span>
              </template>
            </el-table-column>
            <!-- <el-table-column
              prop="userPost"
              label="用户头像"
              width="160"
              align="center"
            >
              <template slot-scope="scope">
                <img :src="scope.row.avatarUrl" alt="" mode="widthFix" />
              </template>
            </el-table-column> -->
            <el-table-column
              prop="chainAddr"
              label="区块链地址"
              width="280"
            ></el-table-column>
            <el-table-column
              prop="status"
              label="账号状态"
              width="170"
              align="center"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.status == 1" class="font-success"
                  >使用中</span
                >
                <span v-else-if="scope.row.status == 2" class="font-warning"
                  >已停用</span
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="authStatus"
              label="实名认证"
              width="170"
              align="center"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.authStatus == 1" class="font-normal"
                  >未认证</span
                >
                <span v-else-if="scope.row.authStatus == 2" class="font-success"
                  >已认证</span
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="inviteAmount"
              label="用户下级总消费(/元)"
              width="200"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="inviteCode"
              label="用户邀请码"
              width="200"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="inviteCount"
              label="用户邀请人总数"
              width="200"
              align="center"
            ></el-table-column>
            <el-table-column label="操作" align="center" fixed="right">
              <template slot-scope="scope">
                <div class="flex-box-around">
                  <el-link
                    v-if="scope.row.status == '2'"
                    :underline="false"
                    type="success"
                    @click="goEnable(scope.row.id)"
                    >启用</el-link
                  >
                  <el-link
                    v-if="scope.row.status == '1'"
                    :underline="false"
                    type="danger"
                    @click="goDisable(scope.row.id)"
                    >停用</el-link
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>

          <div class="x-pager right">
            <el-pagination
              background
              layout="sizes, prev, pager, next"
              :current-page.sync="query.pageIndex"
              :page-size="query.pageSize"
              :page-sizes="[10, 20, 30, 50]"
              :total="total"
              @size-change="setPageSize"
              @current-change="setPageIndex"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { UserQuery, Disable, Enable } from "@/api/module/user";

export default {
  name: "UserIndex",
  data() {
    return {
      modal: false,
      query: {
        keyword: "",
        status: "",
        authStatus: "",
        timeArr: [],
        pageIndex: 1,
        pageSize: 10,
      },
      ooid: "",
      dialogshow: false,
      showLoading: false,
      tableData: [],
      total: 0,
    };
  },

  created() {
    this.doQuery();
  },
  methods: {
    // 删除
    goDisable(id) {
      const msg = "<span class='font-danger'>确认要停用该账号吗？</span>";
      this.$alert(msg, "停用提示", {
        dangerouslyUseHTMLString: true,
        showCancelButton: true,
        confirmButtonText: "确定停用",
        center: true,
        callback: (action) => {
          if (action === "confirm") {
            Disable(id).then((r) => {
              this.$message({
                type: "success",
                message: "停用成功",
                duration: 1300,
              });
              this.doQuery();
            });
          }
        },
      });
    },
    // //   停用账户
    // async goDisable(uuid) {
    //   try {
    //     await this.$confirm("是否确定停用该账号", "警告");
    //     const r = await Disable(uuid);
    //     if (r.code == 0) {
    //       this.$message.success("禁用成功");
    //       this.doQuery();
    //     }
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },
    //   启用账户
    async goEnable(uuid) {
      try {
        await this.$confirm("是否确定启用该账号", "温馨提示");
        const r = await Enable(uuid);
        this.$message.success("启用成功");
        this.doQuery();
      } catch (error) {
        console.log(error);
      }
    },

    //查询
    doQuery() {
      let query = this.setQueryParams();
      UserQuery(query).then((r) => {
        this.total = r.total;
        this.tableData = r.list;
      });
    },
    // 设置筛选
    setQueryParams() {
      let query = `?pagination=true&page=${this.query.pageIndex}&pageSize=${this.query.pageSize}`;
      if (this.query.keyword) {
        query += `&keyword=${this.query.keyword}`;
      }
      if (this.query.status) {
        query += `&status=${this.query.status}`;
      }
      if (this.query.timeArr.length) {
        query += `&startTime=${this.query.timeArr[0]}&endTime=${this.query.timeArr[1]}`;
      }
      if (this.query.authStatus) {
        query += `&authStatus=${this.query.authStatus}`;
      }
      return query;
    },
    // 重置筛选
    doQueryReset() {
      this.query = {
        keyword: "",
        status: "",
        authStatus: "",
        timeArr: [],
        pageIndex: 1,
        pageSize: 10,
      };
      this.doQuery();
    },
    // 刷新
    doRefresh() {
      this.showLoading = true;
      this.doQueryReset();
      setTimeout(() => {
        this.$message({
          type: "success",
          message: "数据更新完毕！",
          duration: 1300,
          onClose: () => {
            this.showLoading = false;
          },
        });
      }, 1350);
    },
    // 设置分页
    setPageSize(pageSize) {
      this.query.pageIndex = 1;
      this.query.pageSize = pageSize;
      this.doQuery();
    },

    // 设置页码
    setPageIndex(pageIndex) {
      this.query.pageIndex = pageIndex;
      this.doQuery();
    },
  },
};
</script>

<style lang='less' scoped>
/deep/ .el-table__row {
  .cell {
    img {
      width: 100%;
      height: 100px;
    }
  }
}
</style>